<template>
  <tbody>
  <tr>
    <td v-on:click="openDetail">{{ invoiceObj.receiver_golf_id }}</td>
    <td v-on:click="openDetail">{{ invoiceObj.git_invoice_number }}</td>
    <td v-on:click="openDetail">{{ invoiceObj.accounting_invoice_number }}</td>
    <td v-on:click="openDetail">{{ formatTime(invoiceObj.create_date) }}</td>
    <td v-on:click="openDetail">{{ formatTime(invoiceObj.canceled_date) }}</td>
    <td class="text-center" v-on:click="openDetail">
                <span v-if="invoiceObj.synced_to_accounting === true">
                  <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
                    <path fill-rule="evenodd" clip-rule="evenodd"
                          d="M8.5 17C13.1946 17 17 13.1946 17 8.5C17 3.80545 13.1946 0 8.5 0C3.80545 0 0 3.80545 0 8.5C0 13.1946 3.80545 17 8.5 17ZM13.0654 5.80975C13.1051 5.76879 13.1362 5.72023 13.1568 5.66698C13.1774 5.61373 13.187 5.55688 13.1852 5.49982C13.1833 5.44276 13.17 5.38666 13.1459 5.33487C13.1219 5.28308 13.0877 5.23666 13.0453 5.19839C13.003 5.16011 12.9533 5.13077 12.8994 5.1121C12.8454 5.09343 12.7883 5.08582 12.7313 5.08972C12.6744 5.09363 12.6188 5.10897 12.5679 5.13484C12.517 5.1607 12.4718 5.19655 12.4351 5.24025L7.344 10.866L4.54325 8.1923C4.46176 8.11441 4.35266 8.07209 4.23996 8.07464C4.12726 8.07719 4.02019 8.12441 3.9423 8.2059C3.86441 8.28739 3.82209 8.39649 3.82464 8.50919C3.82719 8.62189 3.87441 8.72896 3.9559 8.80685L7.07285 11.7819L7.38863 12.0836L7.68145 11.7598L13.0654 5.80975Z"
                          fill="#419E6A"/>
                    </svg>

                </span>
      <span v-else>
                 <svg width="17" height="17" viewBox="0 0 17 17" fill="none" xmlns="http://www.w3.org/2000/svg">
<path
    d="M5.1 9.35H11.9C12.1408 9.35 12.3428 9.2684 12.506 9.1052C12.6692 8.942 12.7506 8.74027 12.75 8.5C12.75 8.25917 12.6684 8.05715 12.5052 7.89395C12.342 7.73075 12.1403 7.64943 11.9 7.65H5.1C4.85917 7.65 4.65715 7.7316 4.49395 7.8948C4.33075 8.058 4.24943 8.25973 4.25 8.5C4.25 8.74083 4.3316 8.94285 4.4948 9.10605C4.658 9.26925 4.85973 9.35057 5.1 9.35ZM8.5 17C7.32417 17 6.21917 16.7767 5.185 16.3302C4.15083 15.8837 3.25125 15.2782 2.48625 14.5137C1.72125 13.7487 1.11577 12.8492 0.6698 11.815C0.223833 10.7808 0.000566667 9.67583 0 8.5C0 7.32417 0.223267 6.21917 0.6698 5.185C1.11633 4.15083 1.72182 3.25125 2.48625 2.48625C3.25125 1.72125 4.15083 1.11577 5.185 0.6698C6.21917 0.223833 7.32417 0.000566667 8.5 0C9.67583 0 10.7808 0.223267 11.815 0.6698C12.8492 1.11633 13.7487 1.72182 14.5137 2.48625C15.2787 3.25125 15.8845 4.15083 16.331 5.185C16.7776 6.21917 17.0006 7.32417 17 8.5C17 9.67583 16.7767 10.7808 16.3302 11.815C15.8837 12.8492 15.2782 13.7487 14.5137 14.5137C13.7487 15.2787 12.8492 15.8845 11.815 16.331C10.7808 16.7776 9.67583 17.0006 8.5 17ZM8.5 15.3C10.3983 15.3 12.0062 14.6412 13.3237 13.3237C14.6412 12.0062 15.3 10.3983 15.3 8.5C15.3 6.60167 14.6412 4.99375 13.3237 3.67625C12.0062 2.35875 10.3983 1.7 8.5 1.7C6.60167 1.7 4.99375 2.35875 3.67625 3.67625C2.35875 4.99375 1.7 6.60167 1.7 8.5C1.7 10.3983 2.35875 12.0062 3.67625 13.3237C4.99375 14.6412 6.60167 15.3 8.5 15.3Z"
    fill="#F43C3C"/>
</svg>

               </span>
    </td>
    <td>
      <button type="button" v-on:click="syncInvoice"
              class="text-white w-full text-sm  rounded-none bg-[#4fcfc0] py-[5px] hover:drop-shadow">
        {{ $t('git.sync_to_accounting') }}
      </button>
    </td>
  </tr>
  <tr v-if="open === true" style="padding: 0px !important;">
    <td :colspan="colspan" class="p-[0px]" style="padding: 0px !important;">
      <div class="w-full px-[10px] py-[10px] h-[400px] bg-white">
        <LittleTabs
            :tabs="tabs"
            :button-size="'sm'"
            :switchTabs="switchTabs"
            containerStyle="justify-content-start"
        />
        <div class="w-full mt-[10px]">
          <invoice-occasion-rows v-if="section === 'rows'" :results="invoiceObj.gitinvoicerow_set"/>
          <invoice-occasion-error v-if="section === 'errors'" :results="invoiceObj.gitinvoiceerror_set"/>
        </div>
      </div>
    </td>
  </tr>
  </tbody>
</template>

<script>
import moment from "moment";
import InvoiceOccasionRows from "@core/components/golfrelated/git/invoices/occasions/InvoiceOccasionRows";
import InvoiceOccasionError from "@core/components/golfrelated/git/invoices/occasions/InvoiceOccasionError";

export default {
  name: "InvoiceTbl",
  components: {
    InvoiceOccasionRows, InvoiceOccasionError
  },
  props: {
    invoice: {
      type: Object,
      required: true
    },
    colspan: {
      type: Number,
      required: true
    }
  },
  data: function () {
    return {
      invoiceObj: {},
      open: false,
      section: 'rows',
      tabs: [
        {
          label: 'git.rows',
          component: 'rows',
          active: true,
        },
        {
          label: 'git.errors',
          component: 'errors',
          active: false,
        },
      ]
    }
  },
  methods: {
    formatTime: function (dt) {
      if (!dt) {
        return ''
      }
      let dte = moment(dt).format('HH:mm, YYYY-MM-DD')
      if (dte === 'Invalid date'){
        return ''
      }
      return moment(dt).format('HH:mm, YYYY-MM-DD')
    },
    openDetail: function () {
      this.open = !this.open
    },
    switchTabs(tabIndex) {
      this.tabs.find(item => item.active === true).active = false;

      this.tabs[tabIndex].active = true;
      this.section = this.tabs[tabIndex].component
    },
    syncInvoice: function () {
      this.$emit('syncInvoice', this.invoice.uuid)
    }
  },
  mounted() {
    this.invoiceObj = this.invoice
  }
}
</script>

<style scoped>

</style>