<template>
  <div class="w-full">
    <div class="mb-2 flex justify-end">
      <div
          class="d-flex smallGap text-secondaryColor align-items-center"
          role="button"
          @click="$router.go(-1)"
      >
        <mdicon :width="25" :height="25" name="arrow-left-thick"/>
        <p class="mb-0" v-text="$t('articleSectionCard.backOverview')"/>
      </div>
    </div>
    <b-card>
      <b-row align-h="center">
        <b-col cols="6">
          <span class="flex gap-3 items-center">
            <p class="mb-0 h4 text-gray-800">{{ $t('git.invoice.occasions.header') }} - </p>
            <p class="mb-0 h4 text-gray-800">
              <strong>{{ invoice_id }} </strong>
            </p>
          </span>
        </b-col>
        <b-col cols="6" class="d-flex justify-content-end">
          <div class="d-flex smallGap text-secondaryColor align-items-center">
            <LittleTabs
                :tabs="tabs"
                :switchTabs="switchTabs"
                containerStyle="justify-content-start"
            />
          </div>
        </b-col>
      </b-row>
    </b-card>
    <b-row>
      <b-col cols="12" lg="12">
        <SecondaryCard :title="$t(`${this.section.label}`)" class="">
          <invoices-detail-section v-if="section.name === 'invoice'" :results="invoiceObj.gitinvoice_set"
                                   :is-loading="isLoading"/>
          <!-- <invoice-settings v-if="section.name === 'settings'" :invoice="invoiceObj"/> -->
          <invoice-settings v-if="section.name === 'settings' && invoiceObj.uuid" :invoice="invoiceObj" :key="invoiceObj.git_id"/>
        </SecondaryCard>
      </b-col>
    </b-row>
  </div>
</template>

<script>
import InvoicesDetailSection from "@core/components/golfrelated/git/invoices/occasions/InvoicesDetailSection";
import InvoiceSettings from "@core/components/golfrelated/git/invoices/occasions/InvoiceSettings";
import {mapGetters} from "vuex"

export default {
  name: "SingleInvoiceOccasion",
  components: {InvoicesDetailSection, InvoiceSettings},
  data: function () {
    return {
      isLoading: true,
      section: {
        name: 'settings',
        label: 'git.settings'
      },
      invoiceObj: {
        gitinvoice_set: []
      },
      invoice_id: '',
      singleInvoice: {
        id: ''
      },
      tabs: [
        {
          label: 'git.settings',
          component: 'settings',
          active: true,
        },
        {
          label: 'git.invoices',
          component: 'invoice',
          active: false,
        },
      ]
    }
  },
  methods: {
    switchTabs(tabIndex) {
      this.tabs.find(item => item.active === true).active = false;

      this.tabs[tabIndex].active = true;
      this.section.name = this.tabs[tabIndex].component
      this.section.label = this.tabs[tabIndex].label
    },
    getInvoice() {
      this.$useJwt.getGitAction({URL: `list_invoices/${this.$route.params.invoice_id}`, method: "get"}).then(resp => {
        this.invoiceObj = resp.data.data
        this.invoice_id = resp.data.data.event_id
        this.isLoading = false
      }).catch(() => {
        this.isLoading = false
      })
    }
  },
  mounted() {
    // this.invoice_id = this.$route.params.invoice_id
    this.getInvoice()
    window.Bus.$on('reload-invoice-detail', e => {
      this.getInvoice()
    })
  },
  beforeDestroy() {
    this.$store.commit('git/UPDATE_SINGLE_INVOICE_OCCASION', {})
  },
  computed: {
    ...mapGetters({
      GET_INVOICE_OCCASION: 'git/GET_SINGLE_INVOICE_OCCASION'
    })
  }
}
</script>

<style scoped>

</style>