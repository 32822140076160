<template>
  <div
      class="scrol-area table-responsive h-[300px] overflow-y-scroll overflow-x-hidden border rounded">
    <table class="table table-hover ">
      <thead class="bg-white  sticky">
      <tr>
        <th
            v-for="(item, i) in fields"
            :key="i"
            class="text-center whitespace-nowrap p-2 md:p-0 bg-white" :class="item.class"
        >
          <div
              class="flex justify-between gap-5 items-center w-full"
          >
                        <span class="text-xs text-[#0E0AB8]">{{
                            $t(item.label)
                          }}</span>
          </div>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="(row,i) in results" :key="i">
        <td>{{ row.golf_id}}</td>
        <td>{{ row.first_name}}</td>
        <td>{{ row.last_name}}</td>
        <td>{{ row.article_number}}</td>
        <td>{{ row.description}}</td>
        <td>{{ row.locker_serial_number}}</td>
        <td>{{ row.price}}</td>
        <td>{{ row.discount}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
export default {
  name: "InvoiceOccasionRows",
  props: {
    results: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      fields: [
        {
          label: 'git.member.golfID',
          class: "min-w-[50px]"
        },
        {
          label: 'git.member.first_name',
          class: "min-w-[150px]"
        },
        {
          label: 'git.member.last_name',
          class: "min-w-[150px]"
        },
        {
          label: 'git.article.article_number',
          class: "min-w-[150px]"
        },
        {
          label: 'git.article.description',
          class: "min-w-[200px]"
        },
        {
          label: 'git.locker_number',
          class: "min-w-[50px]"
        },
        {
          label: 'git.article.price',
          class: "min-w-[50px]"
        },
        {
          label: 'git.article.discount',
          class: "min-w-[50px]"
        },
        // {
        //   label: 'git.invoice.credit_date',
        //   class: "min-w-[200px]"
        // },
      ]
    }
  }
}
</script>

<style scoped>

</style>