<template>
  <div
      class="scrol-area table-responsive h-[300px] overflow-y-scroll overflow-x-hidden border rounded">
    <table class="table table-hover ">
      <thead class="bg-white  sticky">
      <tr>
        <th
            v-for="(item, i) in fields"
            :key="i"
            class="text-center whitespace-nowrap p-2 md:p-0 bg-white" :class="item.class"
        >
          <div
              class="flex justify-between gap-5 items-center w-full"
          >
                        <span class="text-xs text-[#0E0AB8]">{{
                            $t(item.label)
                          }}</span>
          </div>
        </th>

      </tr>
      </thead>
      <tbody>
      <tr v-for="(row,i) in results" :key="i">
        <td>{{ row.error_data.error_code }}</td>
        <td>{{ row.error_data[locale_message]}}</td>
      </tr>
      </tbody>
    </table>
  </div>
</template>

<script>
import {mapGetters} from "vuex"

export default {
  name: "InvoiceOccasionError",
  props: {
    results: {
      type: Array,
      required: true
    }
  },
  data: function () {
    return {
      fields: [
        {
          label: '',
          class: "min-w-[100px]"
        },
        {
          label: 'git.message',
          class: ""
        },
      ]
    }
  },
  computed: {
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'swe_error_message' : 'eng_error_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  }
}
</script>

<style scoped>

</style>