<template>
  <div>
    <div
        class="scrol-area b-tablesticky-header table-responsive h-[600px] overflow-y-scroll overflow-x-hidden border rounded">
      <table class="table table-hover ">
        <thead class="bg-white  sticky">
        <tr>
          <th
              v-for="(item, i) in fields"
              :key="i"
              class="text-center whitespace-nowrap p-2 md:p-0 bg-white" :class="item.class"
          >
            <div
                class="flex  gap-1 items-center w-full"
            >
                        <span class="text-xs text-[#0E0AB8]">{{
                            $t(item.label)
                          }}</span>
              <span class="flex">
                    <svg width="8" height="11" v-on:click="onSort(`${item.value}`,'desc')" class="cursor-pointer"
                         viewBox="0 0 8 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                      <path
                          d="M6.50003 6.29688C6.60437 6.29695 6.70637 6.32774 6.79331 6.38542C6.88026 6.44309 6.9483 6.52509 6.98894 6.62118C7.02958 6.71727 7.04103 6.8232 7.02186 6.92576C7.00268 7.02832 6.95373 7.12296 6.88111 7.19788L4.04778 10.1197C3.99827 10.1707 3.93903 10.2113 3.87357 10.2389C3.80811 10.2666 3.73777 10.2809 3.6667 10.2809C3.59563 10.2809 3.52529 10.2666 3.45983 10.2389C3.39437 10.2113 3.33513 10.1707 3.28561 10.1197L0.452281 7.19788C0.379664 7.12296 0.330715 7.02832 0.31154 6.92576C0.292364 6.8232 0.303813 6.71727 0.344456 6.62118C0.385098 6.52509 0.453137 6.44309 0.540082 6.38542C0.627027 6.32774 0.72903 6.29695 0.833364 6.29688H3.13545V1.25C3.13545 1.1091 3.19142 0.973978 3.29105 0.874349C3.39068 0.774721 3.5258 0.71875 3.6667 0.71875C3.80759 0.71875 3.94272 0.774721 4.04235 0.874349C4.14198 0.973978 4.19795 1.1091 4.19795 1.25V6.29688H6.50003Z"
                          fill="#606060"/>
                      </svg>
                    <svg width="8" height="11" v-on:click="onSort(`${item.value}`,'asc')" class="cursor-pointer"
                         viewBox="0 0 8 11" fill="none"
                         xmlns="http://www.w3.org/2000/svg">
                    <path
                        d="M7.16678 4.70274C7.27111 4.70266 7.37312 4.67187 7.46006 4.6142C7.54701 4.55652 7.61504 4.47453 7.65569 4.37843C7.69633 4.28234 7.70778 4.17641 7.6886 4.07385C7.66943 3.97129 7.62048 3.87665 7.54786 3.80174L4.71453 0.879862C4.66501 0.828883 4.60577 0.788357 4.54032 0.760683C4.47486 0.733009 4.40451 0.71875 4.33345 0.71875C4.26238 0.71875 4.19203 0.733009 4.12658 0.760683C4.06112 0.788357 4.00188 0.828883 3.95236 0.879862L1.11903 3.80174C1.04641 3.87665 0.997463 3.97129 0.978288 4.07385C0.959113 4.17641 0.97056 4.28234 1.0112 4.37843C1.05185 4.47453 1.11988 4.55652 1.20683 4.6142C1.29378 4.67187 1.39578 4.70266 1.50011 4.70274H3.8022V9.74961C3.8022 9.89051 3.85817 10.0256 3.9578 10.1253C4.05742 10.2249 4.19255 10.2809 4.33345 10.2809C4.47434 10.2809 4.60947 10.2249 4.7091 10.1253C4.80873 10.0256 4.8647 9.89051 4.8647 9.74961V4.70274H7.16678Z"
                        fill="#606060"/>
                    </svg>

                  </span>
            </div>
          </th>
          <th
              class="text-center whitespace-nowrap p-2 md:p-0 bg-white min-w-[200px]"
          >
            <div
                class="flex justify-between gap-5 items-center w-full"
            >
              <span class="text-xs text-[#0E0AB8]"></span>
            </div>
          </th>

        </tr>
        </thead>
        <invoice-tbl v-for="(invoice,i) in invoiceList" :key="`${i}_${keyValue}`" :invoice="invoice"
                     :colspan="fields.length +1"
                     @syncInvoice="openSyncConfirmation"/>
        <table-shimmer :n-col="fields.length +1" :n-row="8" v-if="isLoading === true"/>
      </table>
    </div>
    <Modal
        id="git-single-actions"
        :hideFooter="true"
        :title="$t('confirmation')"
    >
      <b-card-text
          class="d-flex flex-column text-center align-items-center justify-content-center"
          style="gap: 1rem"
      >
        <img
            src="@/assets/duplioAsset/icons/warningCircle.png"
            width="70px"
            height="70px"
        />
        <p class="mb-0 pb-0">
          {{ $t(`git.invoice.occasions.import_all_invoice_to_acc_confirmation`) }}
        </p>
      </b-card-text>
      <div class="flex justify-end gap-3 mt-2">
        <b-button
            variant="outline-danger"
            size="md"
            :disabled="loading"
            @click="$bvModal.hide('git-single-actions')"
        >
          <span v-text=" $t('Message.cancel')"/>
        </b-button>
        <b-button
            v-on:click="submitAction"
            :disabled="loading"
            variant="primary"
            size="md"
        >
              <span
                  v-text="loading ? $t('Message.loading') : $t('confirm')"
              />
        </b-button>
      </div>
    </Modal>
  </div>
</template>

<script>
import moment from "moment";
import InvoiceTbl from "@core/components/golfrelated/git/invoices/occasions/InvoiceTbl";
import Paginate from "vuejs-paginate";
import TableShimmer from "@core/components/loading/TableShimmer";
import {mapGetters} from "vuex";

export default {
  name: "InvoicesDetailSection",
  components: {InvoiceTbl, paginate: Paginate, TableShimmer},
  props: {
    results: {
      type: Array,
      required: true
    },
    isLoading: {
      type: Boolean,
      default: false,
      required: true
    }
  },
  data: function () {
    return {
      sortValue: '',
      keyValue: new Date().getMilliseconds(),
      loading: false,
      fields: [
        {
          label: 'git.invoice.receiver',
          value: 'receiver_golf_id',
          class: "min-w-[200px]"
        },
        {
          label: 'git.invoice.git_invoice_number',
          value: 'git_invoice_number',
          class: "min-w-[200px]"
        },
        {
          label: 'git.invoice.accounting_invoice_number',
          value: 'accounting_invoice_number',
          class: "min-w-[200px]"
        },
        {
          label: 'git.invoice.create_date',
          value: 'create_date',
          class: "min-w-[200px]"
        },
        {
          label: 'git.invoice.credit_date',
          value: 'canceled_date',
          class: "min-w-[200px]"
        },
        {
          label: 'git.invoice.status',
          value: 'synced_to_accounting',
          class: "min-w-[200px]"
        },
      ],
      invoiceList: [],
      invoiceCopyList: [],
      targetInvoice: null
    }
  },
  methods: {
    onSort: function (sortValue, ordering) {
      let value_not_null = this.invoiceCopyList.filter(item => item[sortValue] !== null)
      if (value_not_null.length === 0) {
        return false
      }
      let dataType = this.getDataType(value_not_null[0][sortValue])
      if (ordering === 'asc') {
        this.invoiceList = this.invoiceCopyList.sort((a, b) => {
          if (dataType === 'integer') {
            let a_value = a[sortValue] !== null ? a[sortValue].replace('-', '').toLocaleLowerCase() : a[sortValue]
            let b_value = b[sortValue] !== null ? b[sortValue].replace('-', '').toLocaleLowerCase() : b[sortValue]
            return parseInt(a_value) - parseInt(b_value)
          } else if (dataType === 'date') {
            let a_value = new Date(a[sortValue])
            let b_value = new Date(b[sortValue])
            return a_value - b_value
          } else {
            return a[sortValue] < b[sortValue]
          }
        })
      } else {
        this.invoiceList = this.invoiceCopyList.sort((a, b) => {
          if (dataType === 'integer') {
            let a_value = a[sortValue] !== null ? a[sortValue].replace('-', '').toLocaleLowerCase() : a[sortValue]
            let b_value = b[sortValue] !== null ? b[sortValue].replace('-', '').toLocaleLowerCase() : b[sortValue]
            return parseInt(b_value) - parseInt(a_value)
          } else if (dataType === 'date') {
            let a_value = new Date(a[sortValue])
            let b_value = new Date(b[sortValue])
            return b_value - a_value
          } else {
            return b[sortValue] < a[sortValue]
          }
        })
      }
      this.keyValue = new Date().getMilliseconds()
    },
    getDataType: function (value) {
      if (typeof value === "boolean") {
        return 'boolean'
      }
      let strValue = value.replace('-', '').toLocaleLowerCase()
      if (moment(value).format('HH:mm, YYYY-MM-DD') !== 'Invalid date') {
        return 'date'
      } else if (!isNaN(parseInt(strValue))) {
        return 'integer'
      } else {
        return 'string'
      }
    },
    formatTime: function (dt) {
      if (!dt) {
        return ''
      }
      return moment(dt).format('HH:mm, YYYY-MM-DD')
    },
    firePagination: function (num) {
      let URL = `?limit=30`
      URL += `&page=${num}`
      this.getMembers(URL)

    },
    openSyncConfirmation(invoiceId) {
      this.targetInvoice = invoiceId
      this.$bvModal.show('git-single-actions')
    },
    submitAction: function () {
      let payload = {"invoice_uuid": this.targetInvoice}
      let mode = {method: 'post', URL: 'import_invoices/',}
      this.loading = true
      this.$useJwt.getGitAction(mode, payload).then(resp => {
        const {data} = resp;

        this.popupMsg(
            this.$t('Message.Success'),
            data[this.locale_message],
            'CheckIcon',
            'success'
        );
        this.loading = false

        this.$bvModal.hide("git-single-actions");
        window.Bus.$emit('reload-invoice-detail')
      }).catch(e => {
        this.loading = false
        this.$bvModal.hide("git-single-actions");
        this.popupMsg(
            this.$t("Message.Failed"),
            e.response.data[this.locale_message],
            "AlertTriangleIcon",
            "danger"
        );
      })
    }
  },
  mounted() {
    this.invoiceList = this.results
    this.invoiceList.map(item => {
      item.open = false
    })
    this.invoiceCopyList = this.invoiceList
  },
  computed: {
    locale_message() {
      return this.GET_LOCALE === 'sv' ? 'message' : 'eng_message'
    },
    ...mapGetters({
      GET_LOCALE: 'appConfig/getLocale'
    })
  }
}
</script>

<style scoped>

</style>